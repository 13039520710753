



























































import { Component, Watch, Vue } from 'vue-property-decorator';
import WidgetStats from '@/components/widgets/stats.vue';
import WidgetBreakdown from '@/components/widgets/breakdown.vue';
import WidgetRadar from '@/components/widgets/radar.vue';

import IdeaList from '@/pages/projects/projects.vue'
import { EventBus } from '@/utils';

const VIEW = {
    STATISTIC: 'statistic',
    LIST: 'list',
}

@Component({
    name: 'home',
    components: {
        WidgetStats,
        WidgetBreakdown,
        WidgetRadar,
        IdeaList,
    },
})
export default class Home extends Vue {
    $store: any;
    private showRadarFullscreen = false;
    private selectedPhaseId = null;

    private async mounted(): Promise<void> {
        EventBus.$on(EventBus.RADAR.SHOW_FULLSCREEN, this.openFullScreen);
    }

    @Watch('$route.query.view')
    onViewChanged(to: any): void {
        this.view = to;

        if(this.view !== VIEW.LIST) {
            this.selectedPhaseId = null;
        }
    }

    @Watch('$route')
    onAreaChanged(to: any): void {
        if (!to.params.area && !to.query.view) {
            this.view = VIEW.STATISTIC;
        }
    }

    @Watch('$route.query.q')
    onSearchQueryChanged(): void {
        if (this.$route.query.q && this.$route.query.q.length > 0 && this.$route.query.view == VIEW.STATISTIC) {
            this.$router.replace({
                query: {
                    ...this.$route.query,
                    view: VIEW.LIST,
                    q: this.$route.query.q
                }
            })
        }
    }

    private setSelectedPhaseId(phaseId: any): void{
        this.selectedPhaseId = phaseId;
    }

    private view: any = VIEW.STATISTIC;

    private async getData(): Promise<void> {
        this.$store.dispatch('dashboard/getUseCases');
        this.$store.dispatch('dashboard/getPhases');
        this.$store.dispatch('dashboard/getStatuses');
    }

    created(): void {
        this.getData();

        this.view = this.$route.query.view || VIEW.STATISTIC;

        if(!this.$route.query.view){
            this.$router.push({
                params: {
                    area:'all',
                },
                query: {
                    view:this.view,
                },
            })
        }

    }

    private openFullScreen(): void {
        this.showRadarFullscreen = true;
    }

    private closeFullScreen(): void {
        this.showRadarFullscreen = false;
    }

    get listView(): boolean {
        return VIEW.LIST === this.view;
    }

    get statisticView(): boolean {
        return VIEW.STATISTIC === this.view;
    }

    private beforeDestroy(): void {
        EventBus.$off(EventBus.RADAR.SHOW_FULLSCREEN);
    }
}
