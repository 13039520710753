





















































import { Component, Vue } from 'vue-property-decorator';
import { DashboardStatsDto } from '@/service-proxies/service-proxies.g';
import { STATUS } from '@/components/status/status.vue';

@Component({
    name: 'widget-breakdown',
})
export default class WidgetBreakdown extends Vue {
    $store: any;

    get groupedUseCases(): DashboardStatsDto[] {
        return this.$store.state.dashboard.groupedUseCases
    }

    getStatsBarWitdh(count: number): string {
        const useCases = ((this.groupedUseCases || []) as Array<any>)
        const max = Math.max(...useCases.map(phase => phase.count))

        const width = 100 * count / max

        return `${width}%`
    }

    getStatusColor(status: any): string {
        return status.statusName ? (STATUS as any)[status.statusName] : '#ddd'
    }

    getStatusBarWitdh(count: number, total: number): string {
        const width = 100 * count / total        

        return `${width}%`
    }
}
