




/* eslint-disable */

import { Component, Vue, Prop } from 'vue-property-decorator';
import { radar_visualization } from './radar.js'
import { STATUS, PHASE } from '@/components/status/status.vue';

const point = {
  quadrant: 0,
  ring: 0,
  label: 'N/A',
  active: false,
  moved: 0
}

@Component({
    name: 'radar',
})
export default class Radar extends Vue {

    @Prop()
    private data!: any[];

    private getData() {
      return this.data;
    }

    private mounted() {
      radar_visualization({
          svg_id: "radar",
          width: 700,
          height: 600,
          colors: {
            background: "#fff",
            grid: "#bbb",
            inactive: "#ddd"
          },
          title: "",
          quadrants: [
            { name: "" },
            { name: "" },
            { name: "" },
            { name: "" },
          ],
          rings: [
            { name: "In Implementation", color: PHASE['In Implementation'] },
            { name: "In Delivery", color: PHASE['In Delivery'] },
            { name: "In PoC", color: PHASE['In PoC'] },
            { name: "Prioritized Use Cases", color: PHASE['Prioritized Use Cases'] }
          ],
          initialSegment: {
            name: ["Ideas Evaluated", "New Ideas Submitted"],
            color: [ PHASE['Ideas Evaluated'], PHASE['New Ideas Submitted'] ],
          },
          print_layout: false,
          // zoomed_quadrant: 0,

          entries: this.getData(),
      });
    }
}

