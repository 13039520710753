









































import { Component, Vue } from 'vue-property-decorator';
import { PHASE } from '@/components/status/status.vue';
import { DashboardStatsDto } from '@/service-proxies/service-proxies.g';

import OverviewFiltersSelection from '@/utils/local-storage/overview-filters-selection';
const filtersSelection = new OverviewFiltersSelection();

@Component({
    name: 'widget-stats',
})
export default class WidgetStats extends Vue {
    $store: any;

    get phases(): DashboardStatsDto[] {
        return this.$store.state.dashboard.groupedUseCases
    }

    get totalNrOfUsecases(): number {
        return this.$store.getters['dashboard/totalNrOfUsecases'];
    }

    private filterByPhase(phaseId: number): void {
        this.$route.query.view !== 'list' && this.$router.push({ query: { view: 'list' }});

        setTimeout(() => {
            this.$emit('filterByPhase', phaseId);
            setTimeout(() => {
                this.$emit('filterByPhase', 0);
            }, 0);
        }, 0);
    }

    private clearFiltersAndShowList(): void {
        filtersSelection.clearStatusFilter()

        this.$route.query.view === 'list' && this.$router.go(0);
        this.$route.query.view !== 'list' && this.$router.push({ query: { view: 'list' }});

        this.$emit('filterByPhase', null);
    }

    getPhaseColor(phase: any): string {
        return phase.phaseName ? (PHASE as any)[phase.phaseName] : '#ccc'
    }
}
