




































import { Component, Prop, Vue } from 'vue-property-decorator';
import { AdamCardConfig } from 'adam.ui-core/dist/src/lib/components/card';
import { STATUS, RING, plottedStatuses } from '@/components/status/status.vue';
import Radar from '@/components/radar/radar.vue';
import { EventBus } from '@/utils';
import { BaseIdeaDto } from '@/service-proxies/service-proxies.g';

@Component({
    name: 'widget-radar',
    components: {
        Radar,
    }
})
export default class WidgetRadar extends Vue {
    $store: any;

    @Prop({ default: false })
    private isExpanded!: boolean;

    private radarCardConfig: AdamCardConfig = {
        hasAction: true,
    }

    get ideas(): BaseIdeaDto[] {
        const ideasArrays = this.$store.state.dashboard.groupedUseCases.map((phase: { ideas: BaseIdeaDto }) => phase.ideas)
        return [].concat(...ideasArrays)
    }

    get ideaRadar(): any[] {
        return this.ideas
            .filter((idea: any) => ~plottedStatuses.indexOf(idea.statusId))
            .map((idea: any) => ({
                label: idea.title,
                ring: (RING as any)[idea.statusId],
                quadrant:
                    this.getPhaseNameByStatusId(idea.statusId) === 'Ideas Evaluated' || this.getPhaseNameByStatusId(idea.statusId) === 'New Ideas Submitted'
                        ? 1
                        : 0,
                statusColor: (STATUS as any)[Object.keys(STATUS)[idea.statusId - 1]],
                sid: idea.statusId,
                moved: 0,
                active: true,
                link: `#/ideas/${idea.id}`
            })
            )
    }

    private openFullScreen(): void {
        EventBus.$emit(EventBus.RADAR.SHOW_FULLSCREEN, true);
    }

    private getPhaseNameByStatusId(statusId: number): string {
        const phase = this.$store.state.dashboard.groupedUseCases.find((phase: { statusCounts: any[] }) => phase.statusCounts.some(status => status.statusId === statusId))
        return phase?.phaseName;
    }
}
